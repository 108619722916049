<template>
  <div
    id="login-screen"
    class="h-screen w-full mx-auto flex items-center justify-center ..."
  >
    <div class="card flex h-auto w-auto align-middle">
      <div id="left-bg" class="hidden bg-cover bg-center lg:flex flex-grow">
        <img class="h-full w-auto m-auto" src="@/assets/images/ipcdc.jpg" />
      </div>
      <div
        v-loading="loading"
        class="w-full lg:w-1/2 login bg-gray-200 h-auto content-center pt-4 px-8"
      >
        <div class="justify-self-center self-center w-full">
          <div class="flex flex-col justify-center">
            <div class="flex justify-between">
              <div class="py-2">
                <h3 class="text-2xl text-blue-700">Welcome Aboard</h3>
                <h5 class="text-2xl text-blue-700">Sign In to your Account</h5>
              </div>
              <img src="@/assets/images/tmone-logo.png" id="logo" />
            </div>
            <div class="py-4">
              <el-form
                :model="form"
                :rules="rules"
                ref="loginForm"
                @submit.native.prevent="getLoginKey"
              >
                <el-form-item prop="Username" class="w-full">
                  <el-input
                    placeholder="Username"
                    v-model="form.Username"
                    prefix-icon="el-icon-user"
                    @keyup.enter.native="getLoginKey"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="Password" class="w-full">
                  <el-input
                    placeholder="Password"
                    v-model="form.Password"
                    prefix-icon="el-icon-lock"
                    @keyup.enter.native="getLoginKey"
                    show-password
                  ></el-input>
                </el-form-item>
                <el-button
                  class="mt-4 lg:mt-0"
                  type="primary"
                  round
                  @click="getLoginKey"
                  >Generate Login Key</el-button
                >
                &nbsp;
                <a
                  href="javascript:void(0)"
                  class="text-sm float-right -mt-2 lg:-mt-0"
                  @click="
                    forgetPasswordDialogVisible = true
                    forgetPasswordForm.username = ''
                  "
                >
                  Forgot Password?
                </a>
              </el-form>
            </div>
            <div class="mt-2">
              <el-form
                :model="form"
                :rules="rulesSecretCode"
                ref="secretCodeForm"
                @submit.native.prevent="doLogin"
              >
                <el-form-item prop="SecretCode" class="w-full">
                  <el-input
                    placeholder="Login Key"
                    v-model="form.SecretCode"
                    prefix-icon="el-icon-key"
                    show-password
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
            <div class="flex justify-between">
              <el-button type="primary" class="w-full" @click="doLogin" round
                >Login</el-button
              >
              <el-button
                class="w-full"
                round
                v-if="false"
                @click="$router.push({ name: 'Register' })"
                >Register</el-button
              >
            </div>
            <div class="flex justify-center items-center text-right">
              <strong class="text-gray-800 text-sm">
                In collaboration with
              </strong>
              <img src="/img/schneider.png" class="ml-2 w-24" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="Reset Password"
      :visible.sync="forgetPasswordDialogVisible"
      width="30%"
      v-loading="submitResetPassword"
      element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <el-form
        :model="forgetPasswordForm"
        ref="forgetPasswordForm"
        label-width="120px"
        @submit.native.prevent="submitFormResetPassword"
      >
        <el-form-item
          label-position="left"
          prop="username"
          label="Username"
          :rules="[
            {
              required: true,
              trigger: 'change',
              validator: validateUsername
            }
          ]"
        >
          <el-input
            v-model="forgetPasswordForm.username"
            v-on:keydown.enter.prevent="submitFormResetPassword"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div class="flex justify-end">
        <el-button @click="forgetPasswordDialogVisible = false"
          >Cancel</el-button
        >
        <el-button type="primary" @click="submitFormResetPassword()"
          >Submit</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
export default {
  data() {
    var validateUsername = async (rule, value, callback) => {
      var regexExp = new RegExp(
        '^([a-z0-9_-]{3,16})|(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
      )

      if (value == "") {
        callback(new Error("Username is required"))
      } else if (!regexExp.test(value)) {
        callback(new Error("Invalid Username"))
      } else {
        callback()
      }
    }

    return {
      loading: false,
      form: {
        Username: "",
        Password: "",
        SecretCode: ""
      },
      rules: {
        Username: [
          { required: true, trigger: "change", validator: validateUsername }
        ],
        Password: [
          { required: true, message: "Password is required", trigger: "change" }
        ]
      },
      rulesSecretCode: {
        SecretCode: [
          {
            required: true,
            message: "Login Key is required",
            trigger: "change"
          }
        ]
      },
      submitResetPassword: false,
      forgetPasswordDialogVisible: false,
      forgetPasswordForm: {
        username: ""
      }
    }
  },
  computed: {
    ...mapGetters({
      status: "authStatus",
      message: "message"
    })
  },
  methods: {
    async validateUsername(rule, value, callback) {
      var regexExp = new RegExp(
        '^([a-z0-9_-]{3,16})|(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
      )

      if (value == "") {
        callback(new Error("Username is required"))
      } else if (!regexExp.test(value)) {
        callback(new Error("Invalid Username"))
      } else {
        callback()
      }
    },
    async getLoginKey() {
      try {
        this.$refs["loginForm"].validate(async (valid) => {
          if (valid) {
            this.loading = true
            await this.$store.dispatch("getLoginKey", this.form)

            if (this.status == "error") {
              this.$notify.error({
                message: this.message
              })
            } else {
              this.$notify.success({
                message: this.message
              })
            }
            this.loading = false
          } else {
            this.loading = false
            this.$notify.error({
              message: "Username & Password required"
            })
          }
        })
      } catch (e) {
        this.loading = false
        this.$notify.error({
          message: this.message
        })
      }
    },
    async doLogin() {
      this.loading = true
      try {
        this.$refs["secretCodeForm"].validate(async (valid) => {
          if (valid) {
            await this.$store.dispatch("getToken", this.form)

            if (this.status == "error") {
              this.$notify.error({
                message: this.message
              })
            } else {
              await this.$store.dispatch("getUserProfile")

              this.$message.success("You are successfully logged in")
              this.$router.push("/")
            }
            this.loading = false
          } else {
            this.loading = false
            this.$notify.error({
              message: "Login Key is required"
            })
          }
        })
      } catch (e) {
        this.loading = false
        this.$notify.error({
          message: this.message
        })
      }
    },
    async resetPassword() {
      try {
        var _this = this
        this.submitResetPassword = true
        const res = await this.$axios
          .post(
            "_Auth/ForgotPassword?username=" + this.forgetPasswordForm.username
          )
          .then(function (response) {
            // console.log(res)
            if (response.status === 200) {
              _this.forgetPasswordDialogVisible = false
              _this.submitResetPassword = false
              _this.forgetPasswordForm.email = ""
              _this.$message({
                showClose: true,
                message:
                  "Request to reset password has been sent, please check your email",
                type: "success",
                duration: 3000
              })
            }
          })
          .catch(function (error) {
            _this.submitResetPassword = false
            _this.$message({
              showClose: true,
              message: error.response.data.message,
              type: "error",
              duration: 5000
            })
          })
      } catch (e) {
        console.log(e)
        this.submitResetPassword = false
        this.$message({
          showClose: true,
          message: "Failed to reset password. Try again.",
          type: "error",
          duration: 3000
        })
      }
    },
    async submitFormResetPassword() {
      this.$refs["forgetPasswordForm"].validate((valid) => {
        if (valid) {
          this.resetPassword()
        } else {
          console.log("error submit!!")
          return false
        }
      })
    }
  }
}
</script>

<style>
#login-screen {
  background-image: url("~@/assets/images/bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}
#login-screen #logo {
  height: 62px;
}
.bg-side {
  background-image: url("https://www.tmone.com.my/assets/images/thumbnails/ipcdc.jpg");
  background-repeat: no-repeat;
  background-position: center bottom;
}
.card-login {
  width: 450px;
}

.el-loading-spinner {
  @apply flex flex-col justify-center items-center;
}

#login-screen #left-bg {
  background-color: rgb(156, 202, 238);
  background: linear-gradient(
    176deg,
    rgb(232, 245, 252) 0%,
    rgb(131, 186, 231) 41%,
    rgb(35, 87, 161) 100%
  );
  background-size: 470px 100%;
}
#login-screen .el-form .el-input__prefix {
  background: #409eff;
  color: #f8faff;
  font-size: 1.2rem;
  padding: 0 6px;
  left: 0 !important;
  border-radius: 4px 0px 0px 4px;
}

#login-screen .el-form .el-input--prefix .el-input__inner {
  padding-left: 45px;
}

#login-screen .el-form-item.is-error .el-input__inner,
#login-screen .el-form-item.is-error .el-input__inner:focus,
#login-screen .el-form-item.is-error .el-textarea__inner,
#login-screen .el-form-item.is-error .el-textarea__inner:focus,
#login-screen .el-message-box__input input.invalid,
#login-screen .el-message-box__input input.invalid:focus {
  border: 1px solid #dcdfe6;
}
</style>
